.ant-menu-submenu-popup ul.ant-menu {
  background-color: #141414;
  color: #ffffff;
  font-size: 12px;
}

.ant-menu-submenu-popup ul.ant-menu li.ant-menu-item {
  background-color: #141414;
  color: #ffffff;
  margin: 0 auto;
  padding: 0 auto;
}

.ant-menu-submenu-popup ul.ant-menu li.ant-menu-item:hover {
  background-color: #38b1cc;
}

.ant-menu-submenu-popup ul.ant-menu li.ant-menu-item a {
  color: #ffffff;
}

.ant-input-status-error {
  color: #141414 !important;
}

.tabs-drop-down-items ul {
  background-color: #2e2e2e !important;
}

.tabs-drop-down-items ul li {
  color: #ffffff !important;
}

.tabs-drop-down-items ul li span em {
  font-style: normal !important;
}

.tabs-drop-down-items ul li span em::before {
  content: ' (';
}

.tabs-drop-down-items ul li span em::after {
  content: ')';
}

.tabs-drop-down-items ul li:hover {
  background-color: #38b1cc;
  color: #ffffff;
}

.ant-select-selection-item {
  color: #bfbfbf !important;
}

@font-face {
  font-family: 'r360';
  src: url('./layout/fonts/r360.svg');
  src: url('./layout/fonts/r360.eot?hstjln#iefix') format('embedded-opentype'),
    url('./layout/fonts/r360.ttf?hstjln') format('truetype'), url('./layout/fonts/r360.woff?hstjln') format('woff'),
    url('./layout/fonts/r360.svg?hstjln#r360') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.rc-virtual-list-scrollbar-thumb {
  background-color: #ffffff !important;
}
.rc-virtual-list-scrollbar {
  display: block !important;
}
